import React from "react";
import { Link, graphql } from "gatsby";

// Layout Imports
import Default from '../layouts/default';
import Seo from '../layouts/seo';

// Components
import Carousel from '../components/carousel';
import Introduction from '../components/introduction';
import Tips from '../components/tips';

const Index = (props) => {
  return (
    <Default>
      <Seo title='Home' />

      <Carousel />

      <Introduction data={props.data} />
      
      <Tips text={(
				<>
					<h2><span role='img' aria-hidden='true'>&#128197;</span> New Tax Year 2024/25</h2>
					<p>All of our tools have been updated for the new tax year (starting 6th April)</p>
				</>
			)} />
    </Default>
  );
};

export default Index;

export const ToolFilterQuery = graphql`
  query ToolFilterQuery {
    categories: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
    tools: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/src/tool-info/"}},
      limit: 2000,
      sort: {fields: [frontmatter___new], order: DESC},
    ) {
      nodes {
        frontmatter {
          path
          featured
          new
          tags
          title
          disabled
          description
          disabled
          image {
              relativePath
          }
          category
        }
      }
    }
  }
`;