import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from "gatsby";
import kebabCase from "lodash/kebabCase";
import pluralize from 'pluralize';

// Component imports
import Cards from '../cards';

// Style imports
import '../../sass/components/introduction.scss';

const Introduction = ({
    data,
}) => {
    const categoryData = data.categories.group.sort((a, b) => (a.totalCount < b.totalCount) ? 1 : ((b.totalCount < a.totalCount) ? -1 : 0));
    const toolData = data.tools.nodes;

    const [state, setState] = useState({
        tags: [
            {
                name: 'Income',
                active: false,
            },
            {
                name:  'Tax',
                active: false,
            },
            {
                name: 'Student',
                active: false,
            },
            {
                name: 'Property',
                active: false,
            },
            {
                name:  'Benefits',
                active: false,
            },
            {
                name:  'Pension',
                active: false,
            }
        ],
        activeTags: [],
        filteredTools: [],
    });

    const handleTagChange = (index) => {
        const updatedTags = [...state.tags];

        updatedTags[index].active = !state.tags[index].active;

        // Filter active tags from array and create new array of tag names
        const activeTags = updatedTags.filter(tag => tag.active).map(tag => tag.name);

        // Compare duplicated toolData array tags to activeTags and filter results
        const filteredResults = toolData.filter(object => object.frontmatter.tags.some(tag => activeTags.includes(tag)));
        const sortedResults = filteredResults.sort((a, b) => a.frontmatter.featured ? -1 : b.frontmatter.featured ? 1 : 0);

        setState({
            ...state,
            tags: updatedTags,
            activeTags: activeTags,
            filteredTools: sortedResults,
        });
    };
    
    return (
        <>
            <section className='introduction' aria-label='Introduction and tool filtering'>
                <div className='introduction__inner'>
                    <h2 className='introduction__heading'>Our tools</h2>

                    <p className='introduction__description'>We have lots of free tools to choose from - all created to help you easily calculate your personal finances, to better understand your property costs or even predict your student loan repayments</p>
                
                    <h3 className='introduction__subheading'>Choose a category</h3>

                    <ul className='introduction__tag-list'>
                        {state.tags.map((tag, index) => (
                            <li className='introduction__tag-item' key={index}>
                                <button
                                    className={`introduction__tag${state.tags[index].active ? ' introduction__tag--active' : ''}`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        handleTagChange(index);
                                    }}
                                >
                                    <span className='accessible'>{state.tags[index].active ? 'Remove' : 'Add'} </span>
                                    {tag.name}
                                    <span className='accessible'> filter</span>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>

            <section className='introduction__grid' aria-label='Finance tools listing and selection'>
                <div className='introduction__inner'>
                    {state.tags.some(tag => tag.active) ? (
                        <Cards heading={`${pluralize('Category', state.activeTags.length)}: ${state.activeTags.map(item => item).join(', ')}`}>
                            {state.filteredTools.map((tool, index) => {
                                if (tool.frontmatter.disabled) return null;

                                const toolImage = tool.frontmatter.image.relativePath;

                                return (
                                    <Cards.Item
                                        destination={`/${kebabCase(tool.frontmatter.category)}/${tool.frontmatter.path}`}
                                        image={`${withPrefix("/")}img/${toolImage}`}
                                        isNew={tool.frontmatter.new}
                                        isFeatured={tool.frontmatter.featured}
                                        key={index}
                                    >
                                        {tool.frontmatter.title}
                                    </Cards.Item>
                                )
                            })}
                        </Cards>
                    ) : (
                        <>
                            {categoryData && categoryData.map((category, index) => {
                                const filteredTools = toolData && toolData.filter(tool => tool.frontmatter.category === category.fieldValue);
                                const sortedTools = filteredTools.sort((a, b) => a.frontmatter.featured ? -1 : b.frontmatter.featured ? 1 : 0);

                                return (
                                    <Cards
                                        heading={`${category.fieldValue} tools`}
                                        key={index}
                                    >
                                        {sortedTools.map((tool, index) => {
                                            if (tool.frontmatter.disabled) return null;
                                            
                                            const toolImage = tool.frontmatter.image.relativePath;

                                            return (
                                                <Cards.Item
                                                    destination={`/${kebabCase(category.fieldValue)}/${tool.frontmatter.path}`}
                                                    image={`${withPrefix("/")}img/${toolImage}`}
                                                    isNew={tool.frontmatter.new}
                                                    isFeatured={tool.frontmatter.featured}
                                                    key={index}
                                                >
                                                    {tool.frontmatter.title}
                                                </Cards.Item>
                                            )
                                        })}
                                    </Cards>
                                )
                            })}
                        </>
                    )}
                </div>
            </section>
        </>
    );
};

Introduction.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Introduction;
