import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// Components
import Content, { HTMLContent } from "../../components/content-renderer";

// Style Imports
import '../../sass/components/carousel-item.scss';

const CarouselItem = ({
  path,
  isNew,
  title,
  body,
  imagePath,
  isDark,
  background,
  ctaText,
}) => {
    const PageContent = HTMLContent || Content;

    const baseClass = 'carousel-item';
    const darkClass = isDark ? 'carousel-item--dark' : '';
    const classes = [baseClass, darkClass].filter(Boolean).join(' ');
    
    return (
        <article
            className={classes}
            style={{
                backgroundImage: `linear-gradient(251.92deg, ${background} 0.11%, ${background} 46.01%, #4B37CC 100%)`,
            }}
        >
            <span className='carousel-item__circles'></span>

            <div className='carousel-item__inner'>
                {imagePath && (
                    <div className='carousel-item__image-wrapper'>
                        <img
                            className='carousel-item__image'
                            src={imagePath}
                            alt=''
                            aria-hidden='true'
                        />
                    </div>
                )}
                
                <div className='carousel-item__content'>
                    <div className='carousel-item__heading-wrapper'>
                        {isNew && (
                            <p className='carousel-item__badge'>New</p>
                        )}

                        <h2 className={`carousel-item__heading${isNew ? ' carousel-item__heading--indent' : ''}`}>
                            {title}
                        </h2>
                    </div>

                    {body && (
                        <div className='carousel-item__description'>
                            <PageContent content={body} />
                        </div>
                    )}
                    
                    <Link className='carousel-item__cta' to={`/${path}`}>{ctaText}</Link>
                </div>
            </div>
        </article>
    );
};

CarouselItem.defaultProps = {
    ctaText: 'Read more',
    background: '#4B37CC',
};

CarouselItem.propTypes = {
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    isNew: PropTypes.bool,
    imagePath: PropTypes.string,
    isDark: PropTypes.bool,
    background: PropTypes.string,
    ctaText: PropTypes.string,
};

export default CarouselItem;