import React from 'react';
import { withPrefix, graphql, StaticQuery } from "gatsby";
import Slider from "react-slick";

// Component imports
import CarouselItem from './item';

// Style imports
import '../../sass/components/carousel.scss';

const Carousel = () => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    allMarkdownRemark(
                        filter: {
                            fileAbsolutePath: {
                                regex: "/src/promotions/"
                            },
                        },
                        sort: { fields: [frontmatter___first, frontmatter___date], order: DESC }
                        limit: 5
                    ) {
                        nodes {
                            html
                            frontmatter {
                                path
                                first
                                new
                                date(formatString: "MMMM DD, YYYY")
                                dark
                                title
                                image {
                                    relativePath
                                }
                                background
                                ctaText
                            }
                        }
                    }
                }
            `}

            render={data => {
                const settings = {
                    className: "carousel__container",
                    accessibility: true,
                    arrows: false,
                    dots: true,
                    infinite: true,
                    speed: 1000,
                    slidesToShow: 1,
                    autoplay: true,
                    draggable: true,
                    autoplaySpeed: 7500,
                    slidesToScroll: 1,
                };

                const pageInfo = data.allMarkdownRemark.nodes;
                
                return (
                    <section className='carousel'>
                        <h2 className='accessible'>Promotions</h2>
                        <Slider {...settings}>
                            {pageInfo.map((item, index) => {
                                const headerImage = pageInfo[index].frontmatter.image.relativePath;
                                
                                return (
                                    <CarouselItem
                                        path={item.frontmatter.path}
                                        imagePath={`${withPrefix("/")}img/${headerImage}`}
                                        isNew={item.frontmatter.new}
                                        isDark={item.frontmatter.dark}
                                        title={item.frontmatter.title}
                                        body={item.html}
                                        background={item.frontmatter.background}
                                        ctaText={item.frontmatter.ctaText}
                                        key={index}
                                    />
                                );
                            })}
                        </Slider>
                    </section>
                );
            }}
        />
    );
};

export default Carousel;